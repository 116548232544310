// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host::ng-deep .main {
  padding: 0 0 0 0 !important;
}

#exit-button {
  background-color: white;
  color: #1f2129;
}

b {
  font-weight: bold;
  font-family: "Mukta Mahee", sans-serif;
}`, "",{"version":3,"sources":["webpack://./projects/onboarding/src/app/shared-module/pages/rejected-screen/rejected-screen.component.scss"],"names":[],"mappings":"AAAA;EACE,2BAAA;AACF;;AAEA;EACE,uBAAA;EACA,cAAA;AACF;;AACA;EACE,iBAAA;EACA,sCAAA;AAEF","sourcesContent":[":host::ng-deep .main {\n  padding: 0 0 0 0 !important;\n}\n\n#exit-button {\n  background-color: white;\n  color: #1f2129;\n}\nb {\n  font-weight:bold;\n  font-family: \"Mukta Mahee\", sans-serif;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
