// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host {
  display: flex;
  height: 64px;
}

div.avatar {
  flex: 0 0 64px;
}
div.avatar img {
  width: 64px;
  height: 64px;
  border-radius: 50%;
}

div.details {
  padding-left: 12px;
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-around;
}`, "",{"version":3,"sources":["webpack://./projects/sp-shared-lib/src/lib/shared/avatar-card/avatar-card.component.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,YAAA;AACF;;AAEA;EACE,cAAA;AACF;AAAE;EACE,WAAA;EACA,YAAA;EACA,kBAAA;AAEJ;;AAEA;EACE,kBAAA;EACA,OAAA;EACA,aAAA;EACA,sBAAA;EACA,uBAAA;EACA,6BAAA;AACF","sourcesContent":[":host {\n  display: flex;\n  height: 64px;\n}\n\ndiv.avatar {\n  flex: 0 0 64px;\n  img {\n    width: 64px;\n    height: 64px;\n    border-radius: 50%;\n  }\n}\n\ndiv.details {\n  padding-left: 12px;\n  flex: 1;\n  display: flex;\n  flex-direction: column;\n  align-items: flex-start;\n  justify-content: space-around;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
