// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `b {
  font-weight: bold;
  font-family: "Mukta Mahee", sans-serif;
}

ul li {
  margin-top: 4px;
}`, "",{"version":3,"sources":["webpack://./projects/onboarding/src/app/sp-long-module/crowdsurance/crowdsurance.component.scss"],"names":[],"mappings":"AAAA;EACE,iBAAA;EACA,sCAAA;AACF;;AAGA;EACE,eAAA;AAAF","sourcesContent":["b {\n  font-weight:bold;\n  font-family: \"Mukta Mahee\", sans-serif;\n}\n\n\nul li {\n  margin-top: 4px;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
