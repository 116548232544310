// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host {
  display: flex;
  width: auto;
  margin-top: 0 !important;
}
:host select {
  text-align: center;
  width: 48px;
  margin-right: 12px;
  -webkit-appearance: none;
}
:host select:not(:focus) {
  border: none;
}
:host :last-child > select {
  width: 68px;
  margin-right: 0;
}
:host ::ng-deep sp-input-wrapper {
  margin-top: 0;
  width: auto;
}
:host sp-date-select {
  margin-top: 0;
}`, "",{"version":3,"sources":["webpack://./projects/gkt-forms-library/src/lib/date-select/date-select.component.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,WAAA;EACA,wBAAA;AACF;AAAE;EACE,kBAAA;EACA,WAAA;EACA,kBAAA;EACA,wBAAA;AAEJ;AAAE;EACE,YAAA;AAEJ;AAAE;EACE,WAAA;EACA,eAAA;AAEJ;AAAE;EACE,aAAA;EACA,WAAA;AAEJ;AAAE;EACE,aAAA;AAEJ","sourcesContent":[":host {\n  display: flex;\n  width: auto;\n  margin-top: 0 !important;\n  select {\n    text-align: center;\n    width: 48px;\n    margin-right: 12px;\n    -webkit-appearance:none;\n  }\n  select:not(:focus){\n    border: none;\n  }\n  :last-child > select {\n    width: 68px;\n    margin-right: 0;\n  }\n  ::ng-deep sp-input-wrapper {\n    margin-top: 0;\n    width: auto;\n  }\n  sp-date-select {\n    margin-top: 0;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
