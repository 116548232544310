// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host::ng-deep .main {
  padding-top: 0px !important;
}`, "",{"version":3,"sources":["webpack://./projects/onboarding/src/app/shared-module/pages/paticipation/paticipation.component.scss"],"names":[],"mappings":"AACE;EACE,2BAAA;AAAJ","sourcesContent":[":host::ng-deep {\n  .main {\n    padding-top: 0px !important;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
